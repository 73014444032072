export const FETCH_PRODUCTS = "FETCH_PRODUCTS";
export const ADD_PRODUCT = "ADD_PRODUCT";
export const UPDATE_PRODUCT  = "UPDATE_PRODUCT";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const FETCH_PRODUCTS_TYPE_DRESSER = "FETCH_PRODUCTS_TYPE_DRESSER";
export const FETCH_PRODUCTS_TYPE_CHEST = "FETCH_PRODUCTS_TYPE_CHEST";
export const FETCH_PRODUCTS_TYPE_BED_FRAME = "FETCH_PRODUCTS_TYPE_BED_FRAME";
export const FETCH_PRODUCTS_TYPE_ARMOIRE = "FETCH_PRODUCTS_TYPE_ARMOIRE";
export const FETCH_PRODUCTS_TYPE_COTTON = "FETCH_PRODUCTS_TYPE_COTTON";
export const FETCH_PRODUCTS_TYPE_OTHER = "FETCH_PRODUCTS_TYPE_OTHER";

//Nguynn
export const FETCH_BESTSELLER_PRODUCTS = "FETCH_BESTSELLER_PRODUCTS";
export const FETCH_NEWARRIVAL_PRODUCTS = "FETCH_NEWARRIVAL_PRODUCTS";
export const UPLOAD = "UPLOAD";
export const FETCH_USERS = "FETCH_USERS";
export const ADD_USER = "ADD_USER";
export const UPDATE_USER  = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const EDIT_USER = "EDIT_USER";

export const LOGIN = "LOGIN";
