import React, { Component } from "react";
import { Link } from "react-router-dom";
import { API_URL } from "../../constants/Config";

export default class RelatedProducts extends Component {
  render() {
    const col = this.props.relatedProducts.map(product => (
      <div key={product.id} className="col-3 ">
        <div className="">
          <Link to={`/product/${product.id}/detail`}>
            <div className="sub-image box-shadow">
              <img src={`${API_URL}/${product.img1}`}></img>
            </div>
          </Link>
          <p>{product.name}</p>
        </div>
      </div>
    ));
    return (
      <div className="related-product">
        <hr></hr>
        <h4>Related Products</h4>
        <div className="row">{col}</div>
      </div>
    );
  }
}
