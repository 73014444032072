import React, { Component } from "react";
import Menu from "./components/Menu/Menu";
import "./App.css";
import routes from "./routes";
import "bootstrap/dist/css/bootstrap.min.css";

import { Switch, Route, BrowserRouter as Router } from "react-router-dom";

class App extends Component {
    render() {
        return (
            <Router>
                <div>
                    <div className="container-fluid">
                        <div className="row">
                            {this.showContentMenus(routes)}
                        </div>
                    </div>
                </div>
            </Router>
        );
    }
    showContentMenus = routes => {
        var result = null;
        if (routes.length > 0) {
            result = routes.map((route, index) => {
                return (
                    <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        component={route.main}
                    />
                );
            });
        }
        return <Switch>{result}</Switch>;
    };
}

export default App;
