import React, { Component } from "react";

export default class PageSwitch extends Component {
  pageSwitch = (type, page, totalPages) => {
    const tmp = parseInt(page, 10);
    if (totalPages == 1) {
      return (
        <ul className="switch-page nav justify-content-center">
          <li className="switch-li  active">
            <a href={`/category/${type}/page/${1}`}>1</a>
          </li>
        </ul>
      );
    }
    if (page == 1) {
      return (
        <ul className="switch-page nav justify-content-center">
          <li className="switch-li  active">
            <a href={`/category/${type}/page/${1}`}>1</a>
          </li>
          <li className="switch-li">
            <a href={`/category/${type}/page/${2}`}>2</a>
          </li>
          <li className="switch-li">
            <a href={`/category/${type}/page/${totalPages}`}>{">>"}</a>
          </li>
        </ul>
      );
    }
    if (page == totalPages)
      return (
        <ul className="switch-page nav justify-content-center">
          <li className="switch-li ">
            <a href={`/category/${type}/page/${1}`}>{"<<"}</a>
          </li>
          <li className="switch-li">
            <a href={`/category/${type}/page/${totalPages - 1}`}>
              {totalPages - 1}
            </a>
          </li>
          <li className="switch-li active">
            <a href={`/category/${type}/page/${totalPages}`}>{totalPages}</a>
          </li>
        </ul>
      );
    if (page > 1 && page < totalPages) {
      return (
        <ul className="switch-page nav justify-content-center">
          <li className="switch-li">
            <a href={`/category/${type}/page/${1}`}>{"<<"}</a>
          </li>
          <li className="switch-li">
            <a href={`/category/${type}/page/${page - 1}`}>{page - 1}</a>
          </li>
          <li className="switch-li active">
            <a href={`/category/${type}/page/${page}`}>{page}</a>
          </li>
          <li className="switch-li">
            <a href={`/category/${type}/page/${tmp + 1}`}>{tmp + 1}</a>
          </li>
          <li className="switch-li">
            <a href={`/category/${type}/page/${this.props.totalPages}`}>
              {">>"}
            </a>
          </li>
        </ul>
      );
    }
  };

  render() {
    const page = this.props.page;
    const type = this.props.type;
    return (
      <div id="switch-page">
        {this.pageSwitch(type, page, this.props.totalPages)}
      </div>
    );
  }
}
