import axios from "axios";
import * as Config from "../constants/Config";

export default function callApi(endpoint, method = "GET", body) {
  return axios({
    method: method,
    url: `${Config.API_URL}/${endpoint}`,
    header: { "Content-Type": "multipart/form-data" },
    data: body
  }).catch(error => {
    console.log(error);
  });
}
