import * as types from "../constants/ActionTypes";
import callApi from "../utils/apiCaller";


export const actFetchProducts = products => {
    return {
        type: types.FETCH_PRODUCTS,
        products
    };
};
export const actFetchProductsRequest = () => {
    return dispatch => {
        return callApi("product", "GET", null).then(res => {
            dispatch(actFetchProducts(res.data.data));
        });
    };
};
export const actFetchProductsTypeDresser = (products) => {
    return {
        type: types.FETCH_PRODUCTS_TYPE_DRESSER,
        products,
    };
};
export const actFetchProductsRequestType = (type) => {
    return dispatch => {
        return callApi(`product/types/${type}`, "GET", null).then(res => {
            dispatch(actFetchProductsTypeDresser(res.data.data));
        });
    };
};
export const actFetchProductsTypeChest = (products) => {
    return {
        type: types.FETCH_PRODUCTS_TYPE_CHEST,
        products,
    };
};
export const actFetchProductsTypeBedFrame = (products) => {
    return {
        type: types.FETCH_PRODUCTS_TYPE_BED_FRAME,
        products,
    };
};
export const actFetchProductsTypeArmoire = (products) => {
    return {
        type: types.FETCH_PRODUCTS_TYPE_ARMOIRE,
        products,
    };
};
export const actFetchProductsTypeCotton = (products) => {
    return {
        type: types.FETCH_PRODUCTS_TYPE_COTTON,
        products,
    };
};
export const actFetchProductsTypeOther = (products) => {
    return {
        type: types.FETCH_PRODUCTS_TYPE_OTHER,
        products,
    };
};

export const actDeleteProduct = id => {
    return {
        type: types.DELETE_PRODUCT,
        id
    };
};

export const actDeleteProductRequest = id => {
    return dispatch => {
        return callApi(`product/${id}`, "DELETE", null).then(res => {
            dispatch(actDeleteProduct(id));
        });
    };
};

export const actAddProduct = product => {
    return {
        type: types.ADD_PRODUCT,
        product
    };
};

export const actAddProductRequest = product => {
    return dispatch => {
        return callApi("product", "POST", product).then(res => {
            dispatch(actAddProduct(res.data.data));
        });
    };
};



export const actGetProduct = product => {
    return {
        type: types.EDIT_PRODUCT,
        product
    };
};
export const actGetProductRequest = id => {
    return dispatch => {
        return callApi(`product/${id}`, "GET", null).then(res => {
            dispatch(actGetProduct(res.data.data[0]));
        });
    };
};

export const actUpdateProduct = product => {
    return {
        type: types.UPDATE_PRODUCT,
        product
    };
};

export const actUpdateProductRequest = product => {
    return dispatch => {
        return callApi(`product/${product.id}`, "PUT", product).then(res => {
            console.log(res, "put image")
            dispatch(actUpdateProduct(res.config.data));
        });
    };
};
//Nguyen them
export const actFetchBestSellerProduct = products => {
  return {
    type: types.FETCH_PRODUCTS,
    products
  };
};
export const actFetchBestSellerProductsRequest = () => {
  return dispatch => {
    return callApi("product/bestseller", "GET", null).then(res => {
      dispatch(actFetchProducts(res.data.data));
    });
  };
};
// user

export const actFetchUsers = users => {
    return {
        type: types.FETCH_USERS,
        users
    };
};
export const actFetchUsersRequest = () => {
    return dispatch => {
        return callApi("user", "GET", null).then(res => {
            dispatch(actFetchUsers(res.data.data));
        });
    };
};

export const actDeleteUser = id => {
    return {
        type: types.DELETE_USER,
        id
    };
};

export const actDeleteUserRequest = id => {
    return dispatch => {
        return callApi(`user/${id}`, "DELETE", null).then(res => {
            dispatch(actDeleteUser(id));
        });
    };
};

export const actAddUser = user => {
    return {
        type: types.ADD_USER,
        user
    };
};

export const actAddUserRequest = user => {
    return dispatch => {
        return callApi("user", "POST", user).then(res => {
            dispatch(actAddUser(res.data.data));
        });
    };
};

export const actGetUser = user => {
    return {
        type: types.EDIT_USER,
        user
    };
};
export const actGetUserRequest = id => {
    return dispatch => {
        return callApi(`user/${id}`, "GET", null).then(res => {
            dispatch(actGetUser(res.data.data[0]));
        });
    };
};

export const actUpdateUser = user => {
    return {
        type: types.UPDATE_USER,
        user
    };
};

export const actUpdateUserRequest = user => {
    return dispatch => {
        return callApi(`user/${user.id}`, "PUT", user).then(res => {
            dispatch(actUpdateUser(res.data.data));
        });
    };
};

export const loginApi = data => {
    return {
        type: types.LOGIN,
        data
    };
};

export const loginApiRequest = data => {
    return dispatch => {
        return callApi("auth/login", "POST", data).then(res => {
            dispatch(loginApi(res.data.data));
        });
    };
};
