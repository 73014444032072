import React, { Component } from "react";
import ProductList from "../../components/ProductList/ProductList";
import ProductItem from "../../components/ProductItem/ProductItem";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../actions/index";
import Menu from "../../components/Menu/Menu";
import LeftSideBar from "../../components/Homepage/LeftSideBar";

class OtherPage extends Component {
	state = {
		check: true
	}
	componentDidMount() {
		var { history } = this.props;
		var access_token = localStorage.getItem("access_token");
		if (access_token) {
			this.props.fetchAllProducts("Other");
		} else {
			history.push("/login");
		}
	}

	componentWillReceiveProps() {
		if (this.state.check === true) {
			this.props.fetchAllProducts("Other");
			this.setState({
				check: false
			})
		}
	}

	onDelete = id => {
		this.props.deleteProduct(id);
	};

	render() {
		var { typeItem } = this.props;

		return (
			<div className="container-fluid">
				<LeftSideBar></LeftSideBar>
				<div className=" data-table col-10">
					<Menu />
					<Link to="/product/add" className="btn btn-info mb-10">
						Add products
          </Link>
					<ProductList>{this.showProducts(typeItem)}</ProductList>
				</div>
			</div>
		);
	}

	showProducts = products => {
		var result = null;
		if (products.length > 0) {
			result = products.map((product, index) => {
				return (
					<ProductItem
						key={index}
						product={product}
						index={index}
						onDelete={this.onDelete}
					/>
				);
			});
		}
		return result;
	};
}

const mapStateToProps = state => {
	return {
		typeItem: state.typeItem
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
		fetchAllProducts: (type) => {
			dispatch(actions.actFetchProductsRequestType(type));
		},
		deleteProduct: id => {
			dispatch(actions.actDeleteProductRequest(id));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(OtherPage);
