import React, { Component } from 'react'

export default class Slogan extends Component {
  render() {
    return (
      <div className="slogan">
        <h2>We always do our best to satisfy our customers.</h2>
      </div>
    );
  }
}
