import React, { Component } from "react";
import Navbar from "../../components/Homepage/Navbar";
import Banner from "../../components/Homepage/Banner";
import LeftSideBar from "../../components/Homepage/LeftSideBar";
import { connect } from "react-redux";
import { actFetchProductsRequest } from "../../actions/index";
import BestSeller from "../../components/Homepage/BestSeller";
import ProductDisplay from "../../components/Homepage/ProductDisplay";
import PageSwitch from "../../components/Homepage/PageSwitch";
import Slogan from "../../components/Homepage/Slogan";
import Footer from "../../components/Homepage/Footer";
import Contact from "../../components/Homepage/Contact";


class ProductList extends Component {
  state = {
    searchString: ""
  };
  componentDidMount() {
    this.props.actFetchProductsRequest();
  }
  _onChange = text => {
    this.setState({ searchString: text })
  };
  render() {
    const type = this.props.match.params.type;
    const page = this.props.match.params.page;
    const productsPerPage = 16;
    const tmp = this.props.products.filter(product => product.type == type);
    const displayedProduct = tmp.filter(
      product =>
        product.name.toLowerCase().includes(this.state.searchString) ||
        product.code.toLowerCase().includes(this.state.searchString)
    );
    const totalProduct = tmp.length;
    const totalPages = Math.ceil(totalProduct / productsPerPage);

    const products = displayedProduct.slice(
      productsPerPage * page - productsPerPage,
      productsPerPage * page
    );

    return (
      <div className="abc container-fluid justify-content-center">
        <Navbar></Navbar>
        <Contact></Contact>
        <Banner></Banner>
        <div className="sub-content">
          <div className="left-sub-ct">
            <p>
              <b>{products.length}</b> products in this page
            </p>
            <p>
              <b>{tmp.length}</b> products in all
            </p>
          </div>
          <div className="right-sub-ct">
            <input
              onChange={e => {
                this._onChange && this._onChange(e.target.value);
              }}
              type="text"
              placeholder="Search"
            ></input>
          </div>
        </div>
        <hr></hr>
        <div className="main-content">
          <ProductDisplay products={products}></ProductDisplay>
        </div>
        <PageSwitch
          type={this.props.match.params.type}
          page={this.props.match.params.page}
          totalPages={totalPages}
        ></PageSwitch>
        <Slogan></Slogan>
        <Footer></Footer>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    products: state.products
  };
};
export default connect(mapStateToProps, { actFetchProductsRequest })(
  ProductList
);
