import React, { Component } from "react";
import UserList from "../../components/UserList/UserList";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../actions/index";
import UserItem from "../../components/UserItem/UserItem";
import Menu from "../../components/Menu/Menu";

class UserListPage extends Component {
    componentDidMount() {
        var { history } = this.props;
        var access_token = localStorage.getItem("access_token");
        if(access_token){
            this.props.fetchAllUsers();
        }else {
            history.push("/login")
        }
    }

    componentWillReceiveProps(){
        this.props.fetchAllUsers();
    }
    onDelete = id => {
        this.props.deleteUser(id);
    };
    // component(){
    //     this.props.fetchAllProducts();
    // }

    render() {
        var { users } = this.props;
        return (
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <Menu />
                <Link to="/user/add" className="btn btn-info mb-10">
                    Add Users
                </Link>
                <UserList>{this.showUsers(users)}</UserList>
            </div>
        );
    }

    showUsers = users => {
        var result = null;
        if (users.length > 0) {
            result = users.map((user, index) => {
                return (
                    <UserItem
                        key={index}
                        user={user}
                        index={index}
                        onDelete={this.onDelete}
                    />
                );
            });
        }
        return result;
    };
}

const mapStateToProps = state => {
    return {
        users: state.users
    };
};

const mapDispatchToProps = (dispatch, props) => {
    return {
        fetchAllUsers: () => {
            dispatch(actions.actFetchUsersRequest());
        },
        deleteUser: id => {
            dispatch(actions.actDeleteUserRequest(id));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserListPage);
