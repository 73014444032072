import React, { Component } from "react";

export default class SubImage extends Component {
  _onClick = (link)=>{
    this.props._onClick();
    this.props.changeImage(link)

  }
  render() {
    const product = this.props.product ? this.props.product : null;
    const image = product => {
      if (product.search("null") == -1)
        return (
          <div className={`${this.props.isChoosen} sub-image `}>
            <img onClick={()=>this._onClick(product)} src={product}></img>
          </div>
        );
      else return <div></div>;
    };

    return (
      <div className="col-3 sub-col-3">{image(product)}</div>
    );
  }
}
