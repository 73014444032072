import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <div className="footer" id="footer">
        <div className="row">
          <div className="col-lg-6 col-6 footer-col">
            <p>
              <b>Home Furniture Design MFG.</b>
            </p>
            <p>
              <b>Address:</b>10001 Pearmain ST., Oakland, CA 94603
            </p>
            <p>
              <b>Email:</b>hfdmfg@yahoo.com
            </p>
            <p>
              <b>Tel: </b>(510) 562-3738
            </p>
            <p>
              <b>Fax: </b>(510) 562-3867
            </p>
          </div>
          <div className="col-lg-6 col-6 footer-col">
            <p>
              {" "}
              <b>Our product</b>
            </p>
            <div>
              <a href="/category/Bed Frame/page/1">Bed Frame</a>
            </div>
            <div>
              <a href="/category/Dresser/page/1">Dresser</a>
            </div>
            <a href="/category/Chest/page/1">Chest</a>
            <div>
              <a href="/category/Armoire/page/1">Armoire</a>
            </div>
            <div>
              <a href="/category/Cotton Batting And Fiber/page/1">
                Cotton Batting and Fiber
              </a>
            </div>
            <div></div>
            <div>
              <a href="/category/Other/page/1">Other</a>
            </div>
          </div>
        </div>
        <hr></hr>
      </div>
    );
  }
}
