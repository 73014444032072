import React, { Component } from "react";
import {Link} from "react-router-dom";
import {IMG_URL} from "../../constants/Config"

class ProductItem extends Component {
    onDelete = id => {
        if (confirm("Do you sure to delete this item?")) { //eslint-disable-line
            this.props.onDelete(id);
        }
    };
    render() {
        var { product, index } = this.props;
        return (
            <tr>
                <td>{index + 1}</td>
                <td>{product.code}</td>
                <td>{product.name}</td>
                <td>{product.type}</td>
                <td>
                    {product.img1 !== null ? <img alt="product img" src={`${IMG_URL}/${product.img1}`} height="90" width="90"/> : null}
                    {/* {product.img2 !== null ? <img src={`${API_URL}/${product.img2}`} height="90" width="90"/> : null}
                    {product.img3 !== null ? <img src={`${API_URL}/${product.img3}`} height="90" width="90"/> : null}
                    {product.img4 !== null ? <img src={`${API_URL}/${product.img4}`} height="90" width="90"/> : null} */}
                </td>
                <td>{product.description.length > 203 ? `${product.description.substring(0,200)}...`:product.description}</td>
                <td>{product.note}</td>
                <td>{product.size}</td>
                <td>{product.color}</td>
                <td>{product.price}</td>
                <td>{product.bestseller === true ? "Yes" : "No"}</td>
                <td>{product.newarrival === true ? "Yes" : "No"}</td>
                <td>
                    <Link to={`/product/${product.id}/edit`} className="btn btn-success mr-10">
                        Edit
                    </Link>
                    <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => this.onDelete(product.id)}
                    >
                        Delete
                    </button>
                </td>
            </tr>
        );
    }
}

export default ProductItem;
