import React, { Component } from "react";

class ProductList extends Component {
    render() {
        return (
          <div className="panel panel-primary">
            <div className="panel-heading">
              <h3 className="panel-title">List Products</h3>
            </div>
            <div className="panel-body">
              <table className="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>Code</th>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Image</th>
                    <th>Description</th>
                    <th>Note</th>
                    <th>Size</th>
                    <th>Color</th>
                    <th>Price</th>
                    <td>BestSeller</td>
                    <td>NewArrival</td>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>{this.props.children}</tbody>
              </table>
            </div>
          </div>
        );
    }
}

export default ProductList;
