import React, { Component } from "react";
import callApi from "../../utils/apiCaller";
import { connect } from "react-redux";
import * as actions from "../../actions/index";
import { Route, Link, withRouter } from "react-router-dom";

const menus = [
	{
		name: "All",
		to: "/all",
		exact: true
	},
	{
		name: "Dresser",
		to: "/dresser",
		exact: false
	},
	{
		name: "Chest",
		to: "/chest",
		exact: false
	},
	{
		name: "Bed Frame",
		to: "/bed-frame",
		exact: false
	},
	{
		name: "Armoire",
		to: "/armoire",
		exact: false
	},
	{
		name: "Cotton Batting And Fiber",
		to: "/cotton-batting-and-fiber",
		exact: false
	},
	{
		name: "Other",
		to: "/other",
		exact: false
	}
];
const MenuLink = ({ label, to, activeOnlyWhenExact }) => {
	return (
		<Route
			path={to}
			exact={activeOnlyWhenExact}
			children={({ match }) => {
				var active = match ? 'active' : '';
				return (
					<li className={`nav-item menu-item ${active}`}>
						<Link to={to}>{label}</Link>
					</li>
				);
			}}
		/>
	);
};
class LeftSideBar extends Component {
	showMenus = menus => {
		var result = null;
		if (menus.length > 0) {
			result = menus.map((menu, index) => {
				return (
					<MenuLink className="nav-item"
						key={index}
						label={menu.name}
						to={menu.to}
						exact={menu.active}
					/>
				);
			});
		}
		return result;
	};
	render() {
		return (
			<div className="container-fluid shadow">
				<div className="sidenav">
					<p className="shadow-sm" id="prd-title">PRODUCTS</p>
					<ul className="nav nav-pills nav-fill">{this.showMenus(menus)}</ul>
				</div>
			</div>
		);
	}
}
const mapStateToProps = state => {
	return {
		products: state.products
	};
};
const mapDispatchToProps = (dispatch, props) => {
	return {
		fetchAllProducts: () => {
			dispatch(actions.actFetchProductsRequest());
		},
		getProductByTypeDresser: (products) => {
			dispatch(actions.actFetchProductsTypeDresser(products));
		},
		getProductByTypeChest: (products) => {
			dispatch(actions.actFetchProductsTypeChest(products));
		},
		getProductByTypeBedFrame: (products) => {
			dispatch(actions.actFetchProductsTypeBedFrame(products));
		},
		getProductByTypeArmoire: (products) => {
			dispatch(actions.actFetchProductsTypeArmoire(products));
		},
		getProductByTypeCotton: (products) => {
			dispatch(actions.actFetchProductsTypeCotton(products));
		},
		getProductByTypeOther: (products) => {
			dispatch(actions.actFetchProductsTypeOther(products));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LeftSideBar);
