import React, { Component } from "react";
import { Route, Link, withRouter } from "react-router-dom";

const menus = [
    {
        name: "Products",
        to: "/product-list",
        exact: false
    },
    {
        name: "Users",
        to: "/user-list",
        exact: false
    },
    {
        name: "Login",
        to: "/login",
        exact: false
    }
];
const MenuLink = ({ label, to, activeOnlyWhenExact }) => {
    return (
        <Route
            path={to}
            exact={activeOnlyWhenExact}
            children={({ match }) => {
                var active = match ? 'active' : '';
                return (
                    <li className={`nav-item-left menu-item-left ${active}`}>
                        <Link to={to}>{label}</Link>
                    </li>
                );
            }}
        />
    );
};

class Menu extends Component {


    logout = () => {
        localStorage.removeItem("access_token");
        this.props.history.push("/login")
    }
    render() {
        return (
          <div className="admin-navbar">
            <ul className="nav nav-pills nav-fill">
              <li className={`nav-item-left menu-item-left`}>
                <Link to="/">Homepage</Link>
              </li>
              {this.showMenus(menus)}
            </ul>
            <div className="logoutBtn ">
              {" "}
              <button className="btn" onClick={this.logout}>
                <img alt="logout" src={require("../../Assets/logout.png")}></img>
              </button>
            </div>
          </div>
        );
    }
    showMenus = menus => {
        var result = null;
        if (menus.length > 0) {
            result = menus.map((menu, index) => {
                return (
                    <MenuLink className= "nav-item-left"
                        key={index}
                        label={menu.name}
                        to={menu.to}
                        exact={menu.active}
                    />
                );
            });
        }
        return result;
    };
}

export default withRouter(Menu);
