import React, { Component } from "react";
import { Dropdown } from "react-bootstrap";
import "../../Style/homepage.css";
import logo from "../../Assets/logo1.png"

export default class Navbar extends Component {
  state = {
    collapse : true
  }
  _toggleCollapse = ()=>this.setState({collapse : !this.state.collapse})
  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-lg shadow mb-5 bg-white rounded">
          <div className="navbar-brand">
            <a className="navbar-brand" href="/">
              <img alt="logo" src={logo}></img>
            </a>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={this._toggleCollapse}
          >
            <span className="navbar-toggler-icon">
              <div></div>
              <div></div>
              <div></div>
            </span>
          </button>
          <div
            className={
              (this.state.collapse ? "collapse" : "") + " navbar-collapse"
            }
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mr-auto">
              {/* <li className="nav-item active">
                  <a className="nav-link" href="/">
                    Logo
                  </a>
                </li> */}

              <li className="nav-item">
                <Dropdown>
                  <Dropdown.Toggle variant="nav-link" id="dropdown-basic">
                    Product
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="/category/Bed Frame/page/1">
                      Bed Frame
                    </Dropdown.Item>
                    <Dropdown.Item href="/category/Dresser/page/1">
                      Dresser
                    </Dropdown.Item>
                    <Dropdown.Item href="/category/Chest/page/1">
                      Chest
                    </Dropdown.Item>
                    <Dropdown.Item href="/category/Armoire/page/1">
                      Armoire
                    </Dropdown.Item>
                    <Dropdown.Item href="/category/Cotton Batting And Fiber/page/1">
                      Cotton Batting And Fiber
                    </Dropdown.Item>
                    <Dropdown.Item href="/category/Other/page/1">
                      Other
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/aboutus">
                  About us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/#footer">
                  Contact
                </a>
              </li>
              <li className="nav-item contact-nav">
                <img alt="contact" src={require("../../Assets/telephone.png")}></img>
                <p className="nav-link">(510) 562-3738</p>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}
