import React, { Component } from "react";
import * as actions from "../../actions/index";
import { connect } from "react-redux";
import Menu from "../../components/Menu/Menu";

class UserActionPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: "",
            username: "",
            password: ""
        };
    }

    componentWillUnmount() {
        // var { match } = this.props;
        // if (match) {
        //     var id = match.params.id;
        //     this.props.editProduct(id);
        // }
        this.setState({
            id: "",
            username: "",
            password: ""
        });
    }

    onChange = e => {
        var target = e.target;
        var name = target.name;
        var value = target.type === "checkbox" ? target.checked : target.value;
        this.setState({
            [name]: value
        });
    };

    onSave = e => {
        var { id, username, password} = this.state;
        var { history } = this.props;
        e.preventDefault();
        var user = {
            id: id,
            username: username,
            password: password
        };
        if (user) {
            this.props.addUser(user);
            history.push("/user-list");
        }
    };

    render() {
        var { username, password } = this.state;

        return (
            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                <Menu />
                <form onSubmit={this.onSave}>
                    <div className="form-group">
                        <label>Username</label>
                        <input
                            type="text"
                            className="form-control"
                            name="username"
                            onChange={this.onChange}
                        />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input
                            type="password"
                            className="form-control"
                            name="password"
                            onChange={this.onChange}
                        />
                    </div>
                    <button type="submit" className="btn btn-primary">
                        Save
                    </button>
                </form>
            </div>
        );
    }
}

// const mapStateToProps = state => {
//     return { itemEditing: state.itemEditing };
// };

const mapDispatchToProps = (dispatch, props) => {
    return {
        addUser: user => {
            dispatch(actions.actAddUserRequest(user));
        },
        // editProduct: id => {
        //     dispatch(actions.actGetProductRequest(id));
        // },
        // updateProduct: product => {
        //     dispatch(actions.actUpdateProductRequest(product));
        // }
    };
};

export default connect(null,
    mapDispatchToProps
)(UserActionPage);
