import React, { Component } from "react";
import imageBanner from "../../Assets/banner.jpg"

export default class Banner extends Component {
  render() {
    return (
      <div className="banner-container">
        <a href="/">
          <img
            className="banner"
            src={imageBanner}
            // src="https://www.jobsbrunei.com/assets/2018/07/Margherita-Pizzeriabanner.jpg"
          ></img>
        </a>
      </div>
    );
  }
}
