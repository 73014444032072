import React, { Component } from 'react'
import ProductImage from './ProductImage'

export default class ProductDisplay extends Component {
  render() {
    const allProducts = this.props.products.map(product=>(
      <div key={product.id} className="col-lg-3 col-md-3 col-6 ">
          <ProductImage product={product}></ProductImage>
      </div>
    ))
    return (
      <div className="container-fluid main_content">
        <div className="row">{allProducts}</div>
      </div>
    );
  }
}
