import React, { Component } from "react";
import { connect } from "react-redux";
import { actFetchProductsRequest } from "../../actions/index";
import ProductDisplay from "./ProductDisplay";

class NewArrival extends Component {
  componentDidMount() {
    this.props.actFetchProductsRequest();
  }
  render() {
    const tmp = this.props.products.filter(
      product => product.newarrival == 1
    );
    const products = tmp.slice(0,4)
    return (
      <div className="best-seller shadow-sm">
        <h3>
          <p>NEW</p> <p style={{ color: "#E53F57" }}>ARRIVAL</p>
        </h3>
        <ProductDisplay products={products}></ProductDisplay>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    products: state.products
  };
};
export default connect(mapStateToProps, { actFetchProductsRequest })(
  NewArrival
);
