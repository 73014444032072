import React, { Component } from "react";
import {Link} from "react-router-dom";

class UserItem extends Component {
    onDelete = id => {
        if (confirm("Do you sure to delete this item?")) { //eslint-disable-line
            this.props.onDelete(id);
        }
    };
    render() {
        var { user, index } = this.props;
        return (
            <tr>
                <td>{index + 1}</td>
                <td>{user.username}</td>
                <td>
                    <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => this.onDelete(user.id)}
                    >
                        Delete
                    </button>
                </td>
            </tr>
        );
    }
}

export default UserItem;
