import {combineReducers} from "redux";
import products from "./products";
import itemEditing from "./itemEditing";
import users from "./users";
import typeItem from "./typeItem";

const myReducer = combineReducers({
    products,
    itemEditing,
    users,
    typeItem
});

export default myReducer;